import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";
import { Button, Stack, Text } from "basis";
import styled from "styled-components";
import { StringParam, useQueryParam } from "use-query-params";

import {
  Container,
  Flex,
  Link,
  LoadingMessage,
  Message,
} from "../../../components";
import { logger } from "../../../core";
import { useCustomerAuth } from "../../../core/auth";
import { retry } from "../../../utils/retry";
import {
  MerchantSettingsError,
  Scene,
  SelectedPaymentMethod,
} from "../../../layout/wallet";
import { linkCard } from "../../../layout/shared/utils";
import { useMerchantSettings } from "../../../hooks";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0;

  button {
    cursor: pointer;
    width: 100%;
    margin-top: 10px;
  }
`;

const RewardsLink = () => {
  const [error, setError] = useState();
  const [result, setResult] = useState();
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const [merchantId] = useQueryParam("merchantId", StringParam);

  const [
    loadingMerchantSettings,
    errorMerchantSettings,
    merchantSettings,
  ] = useMerchantSettings(merchantId);

  const { logout } = useCustomerAuth();

  const handleCancelClick = () => {
    if (result?.cancelUrl) {
      window.location.href = result?.cancelUrl;
    }
  };

  const handleUnlockBenefitsClick = () => {
    if (result?.redirectUrl) {
      window.location.href = result?.redirectUrl;
    }
  };

  useEffect(() => {
    async function handleLinkCard() {
      try {
        const linkCardResult = await retry({
          fn: () => linkCard(location.search),
          onRetryBegin: (numAttempt) =>
            numAttempt > 0 &&
            logger.info(`handleLinkCard(): retry #${numAttempt}`),
          attempts: 3,
          sleepMs: 200,
          sleepMsDelay: [800, 1200, 1400],
        });

        setResult(linkCardResult);

        if (linkCardResult?.error?.code) {
          setError({
            code: linkCardResult?.error?.code,
            message: linkCardResult?.error?.message,
          });
          return;
        }

        if (String(linkCardResult?.redirectUrl) === "") {
          throw new Error("invalid response. redirectUrl is mandatory.");
        }
      } catch (err) {
        console.log("catch");

        logger.error("handleLinkCard(): failed", { err });
        setError({
          code: "Oops. Something went wrong",
          message: "We could not process your request. Please try again.",
        });
      } finally {
        setLoading(false);
      }
    }
    handleLinkCard();
  }, []);

  if (loading || loadingMerchantSettings) {
    return (
      <Scene
        theme={merchantSettings?.theme}
        merchantLogoURL={merchantSettings?.urlLogo}
      >
        <LoadingMessage message="Please wait while we verify your account.." />
      </Scene>
    );
  }

  if (errorMerchantSettings) {
    return (
      <Scene
        theme={merchantSettings?.theme}
        merchantLogoURL={merchantSettings?.urlLogo}
      >
        <Container margin="4 0" padding="2" maxWidth={1100}>
          <Container bg="white" margin="4 0" padding="10 6" maxWidth={800}>
            <MerchantSettingsError />
          </Container>
        </Container>
      </Scene>
    );
  }

  if (error?.code) {
    return (
      <Scene
        theme={merchantSettings?.theme}
        merchantLogoURL={merchantSettings?.urlLogo}
      >
        <Container margin="4 0" padding="2" maxWidth={1100}>
          <Stack gap="6">
            <Flex>
              <Text></Text>

              <Link href="#" onClick={logout} newTab={false}>
                Log out
              </Link>
            </Flex>

            <Text align="left" textStyle="heading4">
              Unlock your benefits
            </Text>
          </Stack>
        </Container>

        <Container bg="white" margin="4 0" maxWidth={1100} shadow>
          <Container bg="white" margin="4 0" padding="10 6" maxWidth={600}>
            <Message
              severity="warning-or-significant"
              bg="secondary.pink.t30"
              title={error?.code}
            >
              {error?.message}
            </Message>

            <StyledWrapper>
              <Button variant="secondary" onClick={handleCancelClick}>
                Cancel
              </Button>
            </StyledWrapper>
          </Container>
        </Container>
      </Scene>
    );
  }

  return (
    <Scene
      theme={merchantSettings?.theme}
      merchantLogoURL={merchantSettings?.urlLogo}
    >
      <Container margin="4 0" padding="2" maxWidth={1100}>
        <Stack gap="6">
          <Flex>
            <Text></Text>

            <Link href="#" onClick={logout} newTab={false}>
              Log out
            </Link>
          </Flex>

          <Text align="left" textStyle="heading4">
            Unlock your benefits
          </Text>
        </Stack>
      </Container>

      <Container bg="white" margin="4 0" maxWidth={1100} shadow>
        <Container bg="white" margin="4 0" padding="4 6" maxWidth={600}>
          <Container>
            <Text textStyle="heading5" margin="8 0 2">
              Confirm
            </Text>

            <Text textStyle="body1" margin="6 0 4">
              Please confirm you want to unlock the benefits for the card below.
            </Text>

            <SelectedPaymentMethod selectedAccount={result?.linkedAccount} />

            <Text margin="6 0 4">
              Card benefits will be automatically applied during checkout.
            </Text>

            <StyledWrapper>
              <Button onClick={handleUnlockBenefitsClick}>
                Unlock benefits
              </Button>

              <Button variant="secondary" onClick={handleCancelClick}>
                Cancel
              </Button>
            </StyledWrapper>
          </Container>
        </Container>
      </Container>
    </Scene>
  );
};

export default RewardsLink;
