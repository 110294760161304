import axiosHelper from "../../../core/axios-helper";
import { linkingServiceBaseUrl } from "../../../core/config";

const LINK_CARD = "/link";

const UNHANDLED_ERROR = {
  code: "unhandled-error",
  message:
    "Oops. Some unhandled error occured while processing your request. Please try again.",
};

export const linkCard = async (queryString) => {
  const payload = queryString
    .slice(1)
    .split("&")
    .map((p) => p.split("="))
    .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

  const resp = await axiosHelper.withCustomerAuth.post(
    LINK_CARD,
    JSON.stringify(payload),
    { baseURL: linkingServiceBaseUrl }
  );

  if (resp?.status != 200) {
    return { error: UNHANDLED_ERROR };
  }

  return resp?.data;
};
